// const AttributesForIndex = {
//   title: "Blog title",
//   slug: "/blog/category-name/blog-slug",
//   description: "SEO Meta description text",
//   date: "YYYY-MM-DD"
// };

// const AttributesForCards = {
//   title: "Blog title",
//   slug: "/blog/category-name/blog-slug",
//   text: "Card text",
//   imageFilterPath: "Path of image card",
//   imageAlt: "alt for card image"
// };

export const banking101Cards = [
  {
    date: "2024-10-11",
    title: "5 Reasons You Should Open a Savings Account",
    slug: "/blog/banking-101/reasons-open-savings-account",
    description:
      "You may be asking, should I open a savings account. WaFd Bank explains the top 5 reasons to open a savings account including things you need to open an account.",
    imageFilterPath: "banking-101/reasons-open-savings-account/thumbnail-5-reasons-to-open-savings-account-100124.jpg",
    imageAlt: "A woman celebrating while using a laptop."
  },
  {
    date: "2024-09-13",
    title: "How to Send Money to Friends and Family",
    slug: "/blog/banking-101/how-to-send-money-to-friends-family",
    description:
      "Find out from WaFd Bank the many ways you can send money to someone and the options available to send money to friends & family including Zelle, checks & more!",
    imageFilterPath: "banking-101/how-to-send-money-to-friends-family/thumbnail-send-money-family-friends-072224.jpg",
    imageAlt: "Friends laughing while eating breakfast at a restaurant."
  },
  {
    date: "2024-08-14",
    title: "What Are Checks Used For",
    slug: "/blog/banking-101/what-are-paper-checks-used-for",
    description:
      "Find out when you would need to use a paper check and general uses and advantages of using a personal check such as making a down payment for a large purchase.",
    imageFilterPath: "banking-101/what-are-paper-checks-used-for/thumbnail-what-are-checks-used-for-081224.jpg",
    imageAlt: "Woman writing a check."
  },
  {
    date: "2024-07-29",
    title: "How to Reorder Checks with WaFd Bank",
    slug: "/blog/banking-101/how-to-reorder-checks",
    description:
      "Learn how to reorder your WaFd Bank checks. Find out the steps to take whether you have a personal checking account, Treasury Prime or Treasury Express account.",
    imageFilterPath: "banking-101/how-to-reorder-checks/thumbnail-how-to-reorder-checks-072224.jpg",
    imageAlt: "Happy woman reordering WaFd Bank checks on her laptop."
  },
  {
    date: "2024-07-09",
    title: "How to Write Out Numbers on Checks",
    slug: "/blog/banking-101/how-to-write-out-numbers-on-checks",
    description:
      "Find how to write numbers on a check in words (including large numbers) in this guide from WaFd Bank so you can fill out a check like a pro.",
    imageFilterPath: "banking-101/how-to-write-out-numbers-on-checks/thumbnail-numbers-in-words-070824.jpg",
    imageAlt: "Woman writing out a check at desk."
  },
  {
    date: "2024-07-05",
    title: "How to Read a Check for Routing, Account Number &amp; Check Number",
    slug: "/blog/banking-101/how-to-read-check",
    description:
      "Learn how to read a check with ease with WaFd Bank's guide. Find out how to locate your account number, routing and check number, and more.",
    imageFilterPath: "banking-101/how-to-read-check/thumbnail-read-a-check-070924.jpg",
    imageAlt: "Someone giving someone else a check."
  },
  {
    date: "2024-07-02",
    title: "How to Check Your Balance Quickly",
    slug: "/blog/banking-101/how-to-check-your-balance",
    imageFilterPath: "banking-101/how-to-check-your-balance/thumbnail-how-to-check-your-balance-quickly-062724.jpg",
    imageAlt: "Mature man outside on a mobile phone.",
    description:
      "Find out how to check your bank account balance quickly at WaFd Bank using voice-activated banking, online banking, or our mobile app. Sign in today!"
  },
  {
    date: "2023-09-26",
    title: "What is a Bank Statement and Why Is It Important",
    slug: "/blog/banking-101/bank-statements-explained",
    description:
      "Curious what a bank statement is and why you would need to review it? Learn from the WaFd Bank team what a bank statement is and the top reasons it's important.",
    imageFilterPath: "banking-101/bank-statements-explained/thumbnail-what-is-a-bank-statement-091423.jpg",
    imageAlt: "Woman reading bank statement letter."
  },
  {
    date: "2024-03-25",
    title: "7 Ways to Increase Your Financial Literacy in 2025",
    slug: "/blog/banking-101/boost-your-financial-knowledge",
    text: "As we continue to experience an economic downturn and high rates of inflation, now is the perfect opportunity to get educated on money matters.",
    description:
      "Read WaFd Bank's guide to help you increase your financial literacy and boost your financial knowledge, to help you budget and increase your savings.",
    imageFilterPath: "banking-101/boost-your-financial-knowledge/boost-your-financial-knowledge-730.jpg",
    imageAlt: "Mother doing bills with her infant daughter."
  },
  {
    date: "2023-02-17",
    title: "CDs vs Savings Account: Pros and Cons of Each",
    slug: "/blog/banking-101/cds-vs-savings-accounts",
    description:
      "Learn about traditional savings accounts, high yield savings and CD accounts including the benefits and downsides of each from the financial experts at WaFd Bank.",
    imageFilterPath: "banking-101/cds-vs-savings-accounts/thumbnail-hys-cds-02162023-730.jpg",
    imageAlt: "Man using laptop at home."
  },
  {
    date: "2022-04-06",
    title: "How to Choose a Bank - Start with 5 Simple Questions",
    slug: "/blog/banking-101/choosing-a-bank",
    text: "Find out the top five things to consider when choosing a bank that's right for you. Read the following steps to help you make your next banking choice.",
    description:
      "Find out the top five things to consider when choosing a bank that's right for you. Read the following steps to help you make your next banking choice.",
    imageFilterPath: "banking-101/choosing-a-bank/thumbnail-banking-101-05082023-730.jpg",
    imageAlt: "Woman using a laptop at home."
  },
  {
    date: "2020-10-16",
    title: "What's the Difference Between a Money Market and a Savings Account?",
    slug: "/blog/banking-101/difference-between-money-market-and-savings-account",
    description:
      "What is the difference between a money market account and a savings account? Find out how these two accounts compare in this article from WaFd Bank.",
    imageFilterPath:
      "banking-101/difference-between-money-market-and-savings-account/thumbnail-money-markets-vs-savings-accounts-03232023-730.jpg",
    imageAlt: "Mature couple smiling while going over savings account options with financial advisor."
  },
  {
    date: "2023-01-17",
    title:
      "High Yield Money Market Account or High Yield Certificates of Deposit? Make Your Money Work Harder For You.",
    slug: "/blog/banking-101/high-yield-money-market-or-certificate-of-deposit-cd",
    text: "Read on to find out why these are great options to grow your savings and make your money work for you.",
    description:
      "Read on to find out why a high yield money market account or cd are great options to grow your savings and make your money work for you.",
    imageFilterPath:
      "banking-101/high-yield-money-market-or-certificate-of-deposit-cd/thumbnail-high-yield-01112023-730.jpg",
    imageAlt: "Happy young couple having a coffee date in cafe, drinking coffee and chatting."
  },
  {
    date: "2023-09-20",
    title: "How Long Are Checks Good For",
    slug: "/blog/banking-101/how-long-checks-good-for",
    description:
      "Learn about stale dates of checks and understand how long checks are good for and what to do if you have an old check and still owed money from the team at WaFd Bank.",
    imageFilterPath: "banking-101/how-long-checks-good-for/thumbnail-how-long-are-checks-good-091523.jpg",
    imageAlt: "Calendar showing multiple months."
  },
  {
    date: "2024-07-30",
    title: "How to Endorse a Check",
    slug: "/blog/banking-101/how-to-endorse-a-check",
    text: "Find out how to endorse a check. WaFd Bank walks you through each step of finding the endorsement area to how to sign a check so you can deposit or cash your check.",
    description:
      "Find out how to endorse a check. WaFd Bank walks you through each step of finding the endorsement area to how to sign a check so you can deposit or cash your check.",
    imageFilterPath: "banking-101/how-to-endorse-a-check/thumbnail-endorse-a-check-071624.jpg",
    imageAlt: "A check with 'How to Endorse a Check' written on it."
  },
  {
    date: "2024-09-19",
    title: "How to Open a Bank Account Online in 4 Steps",
    slug: "/blog/banking-101/how-to-open-bank-account",
    imageFilterPath: "banking-101/how-to-open-bank-account/thumbnail-open-an-account-online-062023.jpg",
    imageAlt: "Man using a smartphone outside in a park.",
    description:
      "Find out how to open a bank account including what you need to open a bank account online, types of banks accounts, and minimum deposit required."
  },
  {
    date: "2023-07-20",
    title: "How to Set Up Direct Deposit",
    slug: "/blog/banking-101/how-to-set-up-direct-deposit",
    description:
      "Explore the steps needed to set up Direct Deposit, the fastest, safest, and most convenient way to deposit funds into your WaFd Bank account automatically.",
    imageFilterPath: "banking-101/how-to-set-up-direct-deposit/thumbnail-direct-deposit-122823.jpg",
    imageAlt: "A woman setting up direct deposit on her laptop."
  },
  {
    date: "2023-04-25",
    title: "How Do I Change My Bank Account From One Bank to Another?",
    slug: "/blog/banking-101/how-to-switch-banks",
    description:
      "Find out what questions to ask your potential new bank with answers to some common questions, as well as steps to take to make the change to a new bank.",
    imageFilterPath: "banking-101/how-to-switch-banks/thumbnail-switch-banks-04182023-730.jpg",
    imageAlt: "Man sitting in living room using a tablet."
  },
  {
    date: "2023-09-19",
    title: "How to Void a Check",
    slug: "/blog/banking-101/how-to-void-check",
    description:
      "Need to void a check? Find out how to void a check in this easy to understand guide from WaFd Bank so the check can't be used for another purpose.",
    imageFilterPath: "banking-101/how-to-void-check/thumbnail-void-a-check-091523.jpg",
    imageAlt: "Writing in the name or company section of a check."
  },
  {
    date: "2024-05-28",
    title: "Money Order vs Cashier's Check",
    slug: "/blog/banking-101/money-orders-vs-cashiers-check",
    description:
      "Read on to learn the difference between money orders and cashier's checks, as well as how and when to use them.",
    imageFilterPath: "banking-101/money-orders-vs-cashiers-check/thumbnail-money-order-vs-cashiers-check-052124.jpg",
    imageAlt: "Woman handing bank teller a card."
  },
  {
    date: "2020-10-16",
    title: "WaFd Bank Premium Checking Account Benefits",
    slug: "/blog/banking-101/new-benefits-protection",
    imageFilterPath: "banking-101/new-benefits-protection/thumbnail-premium-checking-account-benefits-070824.jpg",
    imageAlt: "Video collage.",
    description:
      "Is a premium checking account right for you? Find out the benefits of Green Checking &amp; Stellar Plus checking at WaFd Bank and see if we're the right fit."
  },
  {
    date: "2022-10-21",
    title: "How to Financially Prepare for Economic Uncertainties",
    slug: "/blog/banking-101/prepare-for-economic-uncertainties",
    description: "Learn tips on how to deal with economic uncertainties in the months ahead.",
    imageFilterPath:
      "banking-101/prepare-for-economic-uncertainties/thumbnail-prepare-economic-uncertainties-122823.jpg",
    imageAlt: "Mature middle-aged couple budgeting with laptop and papers."
  },
  {
    date: "2024-08-06",
    title: "16 Questions to Ask a Mortgage Lender",
    slug: "/blog/banking-101/questions-to-ask-loan-officer",
    description:
      "WaFd Bank helps identify the top home loan questions you should ask your mortgage lender to help you before you make an offer to help make the process smoother.",
    imageFilterPath: "banking-101/questions-to-ask-loan-officer/thumbnail-questions-to-ask-loan-officer-101623.jpg",
    imageAlt: "Smiling couple finishing a deal with the loan officer."
  },
  {
    date: "2023-03-28",
    title: "Getting Your Finances on Track",
    slug: "/blog/banking-101/top-budgeting-tips",
    description:
      "Find out the top budgeting tips for beginner savers to help you get your finances on track and set yourself up for financial success from WaFd Bank.",
    imageFilterPath: "banking-101/top-budgeting-tips/thumbnail-getting-finances-on-track-03082023-730.jpg",
    imageAlt: "Happy woman in glasses using calculator and laptop to budget."
  },
  {
    date: "2023-04-21",
    title: "What to Do If You Can't Open a Bank Account",
    slug: "/blog/banking-101/why-cant-open-bank-account-what-to-do",
    description:
      "Frustrated that you can't open a bank  account? Learn why this happened and what you can do to open an account like a Fresh Start account at WaFd Bank.",
    imageFilterPath: "banking-101/why-cant-open-bank-account-what-to-do/thumbnail-fresh-start-03272023-730.jpg",
    imageAlt: "Man relaxing at home, drinking coffee and using a smartphone."
  },
  {
    date: "2024-04-08",
    title: "How to Deposit Money Into Your Account: Step-by-Step Guide",
    slug: "/blog/banking-101/how-to-deposit-money",
    description:
      "Find out how to easily deposit your money at WaFd Bank. Learn how to deposit money into your account via mobile deposit, direct deposit, & ATM/Branch options.",
    imageFilterPath: "banking-101/how-to-deposit-money/thumbnail-mobile-deposit-032224.jpg",
    imageAlt: "Woman taking a photo of a check for mobile deposit."
  }
];

export const buildingCreditCards = [
  {
    date: "2020-10-16",
    title: "7 Moves to Conquer Credit Card Debt",
    slug: "/blog/building-credit/how-to-get-out-of-credit-card-debt",
    text: "The average household with card debt now owes almost &dollar;16,000. Ideas to help you get ahead.",
    description:
      "Is credit card debt bringing you down? Learn from the financial experts at WaFd Bank on how to get out of credit card debt & how to get your finances on track.",
    imageFilterPath:
      "building-credit/how-to-get-out-of-credit-card-debt/thumbnail-conquer-credit-card-debt-03242023-730.jpg",
    imageAlt: "Businessman working on laptop and making online credit card purchase."
  },
  {
    date: "2022-04-21",
    title: "How to Increase Your Credit Score to 800",
    slug: "/blog/building-credit/how-to-boost-your-credit-score",
    description:
      "WaFd Bank shares tips on how to raise your credit score. For more tips, visit WaFd Bank's Banking 101.",
    imageFilterPath:
      "building-credit/how-to-boost-your-credit-score/thumbnail-how-to-boost-your-credit-score-091823.jpg",
    imageAlt: "Couple smiling while paying bills on laptop."
  },
  {
    date: "2023-03-28",
    title: "Using Credit Cards Wisely",
    slug: "/blog/building-credit/how-to-use-credit-cards",
    description:
      "Find out the top ways to use credit cards wisely and build credit including helpful tips to avoid high interest rates from the team at WaFd Bank.",
    imageFilterPath: "building-credit/how-to-use-credit-cards/thumbnail-using-credit-cards-wisely-03092023-250.jpg",
    imageAlt: "Woman using credit card holding her cell phone in living room."
  },
  {
    date: "2020-10-16",
    title: "How to Avoid Delinquent Credit Card Payments",
    slug: "/blog/building-credit/how-to-avoid-delinquent-credit-card-payments",
    text: "What might happen if you miss a credit card payment&quest;",
    description:
      "Are delinquent credit card payments getting you down? Find out how to avoid delinquent payments to save you money from the financial team at WaFd Bank.",
    imageFilterPath:
      "building-credit/how-to-avoid-delinquent-credit-card-payments/thumbnail-credit-card-payments-03082023-730.jpg",
    imageAlt: "Woman writing list of debt on notebook and using calculator."
  },
  {
    date: "2024-07-29",
    title: "What to Know Before You Cancel a Credit Card",
    slug: "/blog/building-credit/things-to-consider-before-cancel-credit-card",
    text: "Why you may want to think twice before you close that credit card you're not using...",
    description:
      "Are you thinking about canceling a credit card? Find out the pros and cons of canceling a credit card from WaFd Bank & when you should cancel your credit card.",
    imageFilterPath:
      "building-credit/things-to-consider-before-cancel-credit-card/thumbnail-what-to-know-before-canceling-cc-072424.jpg",
    imageAlt: "Senior couple sitting on the sofa and discussing finances."
  },
  {
    date: "2020-10-16",
    title: "What is a FICO Score and Why is it Important",
    slug: "/blog/building-credit/what-is-fico-credit-score",
    description:
      "What is a FICO Score and why is it important? Learn about FICO scores from WaFd Bank and ways to improve your credit score to improve your personal financial wealth.",
    imageFilterPath: "building-credit/what-is-fico-credit-score/thumbnail-what-is-a-fico-score-06022023-730.jpg",
    imageAlt: "Woman doing online shopping at home with credit card and smartphone."
  }
];

export const buildingRemodelingCards = [
  {
    date: "2020-10-16",
    title: "Construction-to-Permanent Loans Explained",
    slug: "/blog/building-remodeling/construction-to-permanent-loans-explained",
    description:
      "Learn about WaFd Bank's construction to permanent loan offering that simplifies the building process making it easier for you to build the home of your dreams!",
    imageFilterPath:
      "building-remodeling/construction-to-permanent-loans-explained/thumbnail-construction-to-permanent-loan-010824.jpg",
    imageAlt: "Construction to Permanent Loan Advantages & How They Work"
  },
  {
    date: "2020-10-16",
    title: "3 Things to Consider in a Custom Construction Loan",
    slug: "/blog/building-remodeling/3-things-know-custom-construction-loan",
    text: "What should you expect&quest;",
    description:
      "Find out the top things to consider when getting a construction to permanent loan. Learn why WaFd Bank is one of the best banks to get a construction to permanent loan.",
    imageFilterPath:
      "building-remodeling/3-things-know-custom-construction-loan/thumbnail-custom-construction-loan-1-05152023-730.jpg",
    imageAlt: "Project manager shaking hands with architects in construction site."
  },
  {
    date: "2020-10-16",
    title: "7 Tips for Buying Land to Build a House",
    slug: "/blog/building-remodeling/buying-land-to-build-house",
    description:
      "Find out the top tips from WaFd Bank when buying land to build a house so you find the right lot that fits your needs and budget for your dream home.",
    imageFilterPath: "building-remodeling/buying-land-to-build-house/thumbnail-buying-land-build-house-071023.jpg",
    imageAlt: "Wood model house in empty field at sunset."
  },
  {
    date: "2020-10-16",
    title: "10 Ways to Use a HELOC to Customize Your Home",
    slug: "/blog/building-remodeling/10-ways-use-heloc-home-equity-loan-for-remodel",
    text: "With housing shortages and skyrocketing home prices in many parts of the country, many have opted to make home improvements rather than buying a new home.",
    description:
      "How can I finance my house remodel when construction costs are so high? Find out how WaFd Bank can help you make your new remodel become a reality.",
    imageFilterPath:
      "building-remodeling/10-ways-use-heloc-home-equity-loan-for-remodel/10-ways-to-use-a-heloc-730.jpg",
    imageAlt: "A woman installing kitchen cabinets."
  },
  {
    date: "2020-10-16",
    title: "Remodeling Projects That Add the Most Value to Your Home",
    slug: "/blog/building-remodeling/home-improvements-that-add-value",
    text: "Not all remodel projects are created equal, and you won't always get what you pay for.",
    description:
      "Are you looking to increase the value of your home? Find out the top home improvements that add value to your home from your local financial team at WaFd Bank.",
    imageFilterPath:
      "building-remodeling/home-improvements-that-add-value/thumbnail-remodeling-projects-1-05082023-730.jpg",
    imageAlt: "Woman working on a piece of wood outside, dog laying down nearby."
  },
  {
    date: "2020-10-16",
    title: "DIY Bathroom and Kitchen Upgrades",
    slug: "/blog/building-remodeling/bathroom-kitchen-upgrades-on-budget",
    description:
      "Is it time for a change in your kitchen? Learn about the top tips for kitchen renovations on a budget from WaFd Bank &amp; find out if a kitchen remodel is right for you.",
    imageFilterPath:
      "building-remodeling/bathroom-kitchen-upgrades-on-budget/thumbnail-updating-your-home-02212023-730.jpg",
    imageAlt: "Large updated white kitchen with island."
  },
  {
    date: "2024-05-10",
    title: "5 Backyard Improvements That Add Value",
    slug: "/blog/building-remodeling/5-backyard-projects-with-best-return-on-investment",
    description:
      "When was the last time you upgraded your outdoor patio or backyard landscaping? Here are a few backyard improvements that add value from WaFd Bank.",
    imageFilterPath:
      "building-remodeling/5-backyard-projects-with-best-return-on-investment/thumbnail-backyard-projects-best-return-on-investment-091923.jpg",
    imageAlt: "Man installing outdoor lighting."
  },
  {
    date: "2020-10-16",
    title: "How to Use a HELOC for Home Improvement",
    slug: "/blog/building-remodeling/heloc-for-home-improvement",
    description:
      "Discover how a HELOC (home equity line of credit) with WaFd Bank might be the best way to finance home improvements like a remodel or addition on your house.",
    text: "With housing shortages and skyrocketing home prices in many parts of the country, many have opted to make home improvements rather than buying a new home.",
    imageFilterPath: "building-remodeling/heloc-for-home-improvement/thumbnail-home-improvement-05102023-730.jpg",
    imageAlt: "Couple looking at laptop during home renovation project."
  },
  {
    date: "2020-10-16",
    title: "Pros and Cons to Consider BEFORE You Buy a Fixer-Upper",
    slug: "/blog/building-remodeling/pros-cons-before-buying-fixer-upper",
    text: "Fixer-uppers have a lower up-front cost than their turnkey counterparts.",
    description:
      "Should you buy a fixer upper home? Check out these top pros and cons to figure out if buying a fixer upper is the right decision for you.",
    imageFilterPath: "building-remodeling/pros-cons-before-buying-fixer-upper/thumbnail-fixer-upper-091923.jpg",
    imageAlt: "Couple painting their walls during a home renovation."
  },
  {
    date: "2020-10-16",
    title: "Building vs Buying a House - Which is Better?",
    slug: "/blog/building-remodeling/building-vs-buying-house",
    text: "Tips to help you decide if buying a house or building a house is better for you (and your wallet&excl;).",
    description:
      "Should I buy or build a house? Find out from WaFd Bank, a portfolio lender, what factors you should consider when making this important decision.",
    imageFilterPath: "building-remodeling/building-vs-buying-house/thumbnail-building-vs-buying-061623.jpg",
    imageAlt: "Couple talking about the floor-plan of their new house."
  },
  {
    date: "2020-10-16",
    title: "How to Finance a Home Renovation or Remodel",
    slug: "/blog/building-remodeling/best-way-finance-home-improvements",
    text: "For most of us, our remodel project won't be featured (or paid for) by HGTV.",
    description:
      "Are you thinking about financing home improvements? Find out the different options available to turn your home into your dream home from the team at WaFd Bank.",
    imageFilterPath: "building-remodeling/best-way-finance-home-improvements/blog-financing-small-projects-730.jpg",
    imageAlt: "Couple using a credit card for online shopping."
  },
  {
    date: "2024-03-25",
    title: "How to Build a Custom Home from Foundation Framing to Exterior Finishing",
    slug: "/blog/building-remodeling/first-steps-in-home-building",
    description:
      "Learn the basics of building your dream home including the first step of foundation framing and why it's important. Need a construction loan, turn to WaFd.",
    imageFilterPath: "building-remodeling/first-steps-in-home-building/thumbnail-build-custom-home-032024.jpg",
    imageAlt: "Couple at construction site looking at home building plans."
  }
];

export const communitySupportCards = [
  {
    date: "2023-10-12",
    title: "Financial Inclusion Impact at WaFd Bank",
    slug: "/blog/community-support/financial-inclusion",
    text: "Find out how WaFd Bank is driving financial inclusion with Bank On initiatives, financial coaching, &amp; educational programs to help promote economic development.",
    description:
      "Find out how WaFd Bank is driving financial inclusion with Bank On initiatives, financial coaching, &amp; educational programs to help promote economic development.",
    imageFilterPath: "community-support/financial-inclusion/thumbnail-financial-inclusion-091523.jpg",
    imageAlt: "Grandson and Grandmother using laptop at home."
  },
  {
    date: "2020-11-13",
    title: "Why Boise, Idaho is a Good Place to Live",
    slug: "/blog/community-support/living-in-boise-idaho",
    text: "With its strong economy, affordable cost of living, low crime rates and ample access to outdoor activities to enjoy the area's natural beauty, it's no wonder more and more people are living in Boise, Idaho.",
    description:
      "It's no wonder people want to live in Boise, Idaho with a growing downtown and cultural scene. WaFd Bank has you covered with multiple neighborhood branches.",
    imageFilterPath: "community-support/living-in-boise-idaho/thumbnail-downtown-boise-idaho-011824.jpg",
    imageAlt: "Downtown Boise, Idaho."
  },
  {
    date: "2024-05-06",
    title: "Why Dallas, Texas is Worth Moving To",
    slug: "/blog/community-support/moving-to-dallas-texas",
    description:
      "It's no wonder people are moving to Dallas with big Texas values and a growing metropolitan area. WaFd Bank has you covered with multiple neighborhood locations.",
    imageFilterPath: "community-support/moving-to-dallas-texas/thumbnail-why-move-to-texas-031424.jpg",
    imageAlt: "Dallas Texas suburban park with downtown skyline in background."
  },
  {
    date: "2024-03-19",
    title: "Moving to Salt Lake City, Utah",
    slug: "/blog/community-support/moving-to-salt-lake-city-utah",
    text: "If you are looking for a change, consider moving to Salt Lake City — an area offering big-city amenities, a strong economy, and beautiful, natural surroundings that will not disappoint.",
    description:
      "Discover the top things to know before moving to Salt Lake City, Utah. From cultured city living to the nearby mountain ranges, SLC really has it all!",
    imageFilterPath: "community-support/moving-to-salt-lake-city-utah/thumbnail-moving-to-slc-103023.jpg",
    imageAlt: "Downtown, Salt Lake City, Utah."
  }
];

export const consumerTipsCards = [
  {
    date: "2024-03-18",
    title: "2024 Changes to FDIC Insurance for Trust Accounts",
    slug: "/blog/consumer-tips/fdic-coverage-for-trust-accounts",
    description:
      "Discover how the 2024 changes to FDIC insurance rules impact trust accounts, including both revocable and irrevocable trusts from the team at WaFd Bank.",
    imageFilterPath: "consumer-tips/fdic-coverage-for-trust-accounts/thumbnail-2024-changes-fdic-trust-031424.jpg",
    imageAlt: "Woman with business documents sitting at desk."
  },
  {
    date: "2022-04-22",
    title: "Tips for Cutting Costs Around the House",
    slug: "/blog/consumer-tips/cutting-costs-around-the-house",
    imageFilterPath:
      "consumer-tips/cutting-costs-around-the-house/thumbnail-tip-for-cutting-costs-around-house-101923.jpg",
    imageAlt: "Couple smiling while doing paperwork.",
    description:
      "WaFd Bank shares ideas on how to reduce costs around the house. For more tips, visit WaFd Bank's Banking 101."
  },
  {
    date: "2023-04-24",
    title: "How to Write a Check",
    slug: "/blog/consumer-tips/how-to-write-a-check",
    imageFilterPath: "consumer-tips/how-to-write-a-check/thumbnail-how-to-write-a-check-010824.jpg",
    imageAlt: "How to Write a Check: Step-by-Step Guide",
    description:
      "New to writing a check? Learn how to fill out a check in six steps in WaFd Bank's comprehensive step-by-step check writing guide with photos. "
  },
  {
    title: "3 Ways to Pay For Unexpected Expenses",
    slug: "/blog/consumer-tips/unexpected-expense-how-to-pay-smart-way",
    imageFilterPath: "consumer-tips/unexpected-expense-how-to-pay-smart-way/thumbnail-unexpected-finances-01052023.jpg",
    imageAlt: "Couple sitting at table stressed looking at bills.",
    description:
      "At some point, you may encounter an unexpected event in your life – loss of job, unplanned illness, etc – learn from WaFd Bank how to prepare for unexpected expenses",
    date: "2020-10-16"
  },
  {
    title: "Financial Emergency Preparedness",
    slug: "/blog/consumer-tips/financial-disaster-preparedness",
    imageFilterPath: "consumer-tips/financial-disaster-preparedness/thumbnail-prepare-finances-03072023-730.jpg",
    imageAlt: "Man smiling and looking at paperwork with laptop.",
    description:
      "Find out top tips around financial disaster preparedness so you can prepare your finances for a flood, fire or other disaster you weren't planning on.",
    date: "2020-10-16"
  },
  {
    date: "2023-04-20",
    title: "What is Comparison Shopping and How Can It Help You Save?",
    slug: "/blog/consumer-tips/comparison-shopping",
    imageFilterPath: "consumer-tips/comparison-shopping/thumbnail-comparison-shopping-04202023-730.jpg",
    imageAlt: "Woman and child online shopping.",
    description:
      "Find out what comparison shopping is and the importance of comparison shopping in your daily shopping habits to help you save from you local bank, WaFd Bank."
  },
  {
    title: "5 Ways to Keep Your Home Safe When Traveling",
    slug: "/blog/consumer-tips/holiday-home-safety-tips",
    imageFilterPath: "consumer-tips/holiday-home-safety-tips/thumbnail-keep-home-safe-while-traveling-103023.jpg",
    imageAlt: "Home with many lights on at night.",
    description:
      "Are you wanting to keep your home safe this holiday season? Find out the top holiday home safety tips from WaFd Bank to give you peace of mind.",
    date: "2020-10-16"
  },
  {
    title: "3 Ways to Get Ready Now for Tax Season",
    slug: "/blog/consumer-tips/3-ways-get-ready-for-tax-season",
    description:
      "Are you sweating tax season? Make a plan to save at tax time! Find out our top financial tips to keep you organized from the financial team at WaFd Bank.",
    date: "2020-10-16",
    imageFilterPath: "consumer-tips/3-ways-get-ready-for-tax-season/thumbnail-ready-for-tax-season-091423.jpg",
    imageAlt: "Woman with calculator and papers at home."
  },
  {
    date: "2023-06-02",
    title: "Is a CD Better Than a Savings Account for Emergency Funds?",
    slug: "/blog/consumer-tips/cd-top-off-rainy-day-fund",
    description:
      "Are you thinking about your rainy day fund? Find out how a CD (certificate of deposit) can be a potential savings avenue. Learn about the benefits of a CD today.",
    imageFilterPath: "consumer-tips/cd-top-off-rainy-day-fund/thumbnail-cd-vs-savings-emergency-060223.jpg",
    imageAlt: "Happy couple at home paying bills online on their laptop."
  },
  {
    title: "Safe Deposit Box Top Tips (Including What to Keep and Not Keep)",
    slug: "/blog/consumer-tips/5-things-about-safe-deposit-boxes",
    imageFilterPath: "consumer-tips/5-things-about-safe-deposit-boxes/thumbnail-safe-deposit-boxes-121923.jpg",
    imageAlt: "Woman opening a safe deposit box.",
    description:
      "Find out from the team at WaFd Bank what to keep in a safety deposit box (and what not to keep) along with top tips for using your secure safe deposit box.",
    date: "2024-05-20"
  },
  {
    date: "2022-04-21",
    title: "Realistic Tips to Save Money and Increase Your Savings",
    slug: "/blog/consumer-tips/boost-your-savings",
    description:
      "WaFd Bank shares tips on building your budget, changing some of your spending habits and setting savings goals.",
    imageFilterPath: "consumer-tips/boost-your-savings/thumbnail-increase-savings-091923.jpg",
    imageAlt: "Elderly woman smiling and daughter hugging her."
  },

  {
    title: "Rewards Checking: A Bank Account with Cell Phone Protection ",
    slug: "/blog/consumer-tips/cell-phone-insurance-coverage-facts",
    description:
      "Find out which bank accounts at WaFd Bank offer cell phone protection coverage as well as the details and benefits of the mobile phone protection plan.",
    date: "2024-05-29",
    imageFilterPath: "consumer-tips/cell-phone-insurance-coverage-facts/thumbnail-4-facts-mobile-phone-insurance.jpg",
    imageAlt: "Kneeling person picking up a broken phone on the street."
  },
  {
    title: "Common Financial Questions",
    slug: "/blog/consumer-tips/common-financial-questions-answered",
    description:
      "We've heard your financial questions and the financial experts at WaFd Bank are here to help answer the most common financial questions that have been asked.",
    date: "2024-03-25",
    imageFilterPath:
      "consumer-tips/common-financial-questions-answered/thumbnail-common-financial-questions-answered-031324.jpg",
    imageAlt: "Financial advisor talking with couple in office."
  },
  {
    title: "5 Holiday Budgeting Tips to Help You Stay on Budget and Stretch Your Dollars",
    slug: "/blog/consumer-tips/holiday-financial-tips-to-stretch-your-dollars-for-the-holiday-season",
    description:
      "Learn about the top 5 holiday spending tips to keep your finances in order and your budget happy as we navigate a holiday season like we haven't seen before.",
    date: "2020-11-03",
    imageFilterPath:
      "consumer-tips/holiday-financial-tips-to-stretch-your-dollars-for-the-holiday-season/thumbnail-5-holiday-budgeting-tips-091323.jpg",
    imageAlt: "Happy couple wrapping holiday presents."
  },
  {
    title: "How to Combine Your Finances After Marriage",
    slug: "/blog/consumer-tips/how-to-combine-finances",
    description:
      "Is it that time to combine your finances? Learn how to combine your finances as a couple and what you should know during this exciting time in your life.",
    date: "2020-10-16",
    imageFilterPath: "consumer-tips/how-to-combine-finances/thumbnail-combine-finances-03302023-730.jpg",
    imageAlt: "Happy young couple spending time together outside with coffee."
  },
  {
    date: "2023-04-03",
    title: "Complete Guide on How to Deposit and Cash a Check",
    slug: "/blog/consumer-tips/how-to-deposit-and-cash-check",
    description:
      "Curious how to deposit a check online or cash a check at a bank? Find out how to deposit your money like an expert from your local bank, WaFd Bank.",
    imageFilterPath: "consumer-tips/how-to-deposit-and-cash-check/thumbnail-deposit-or-cash-check-010824.jpg",
    imageAlt: "Walt on his way to deposit a check to buy a car."
  },
  {
    date: "2023-04-20",
    title: "How to Save for Medical Expenses",
    slug: "/blog/consumer-tips/how-to-save-for-medical-expenses",
    description:
      "Learn more about how you can save for medical expenses with accounts like HSA and FSA that give you tax benefits and savings when you need it from WaFd Bank.",
    imageFilterPath: "consumer-tips/how-to-save-for-medical-expenses/thumbnail-medical-expenses-04182023-730.jpg",
    imageAlt: "Baby on mother's lap smiling and getting examined by doctor."
  },
  {
    title: "Top 15 Holiday Savings and Budgeting Tips",
    slug: "/blog/consumer-tips/money-tips-holidays",
    description:
      "Holiday debt hangover leaves individuals stressed out. Learn from WaFd Bank how you can apply some basic money-saving holiday tips to your holiday purchases.",
    date: "2020-10-16",
    imageFilterPath: "consumer-tips/money-tips-holidays/thumbnail-15-holiday-savings-06082023-730.jpg",
    imageAlt: 'Money tied in string and holiday gift tag saying "This is my year to save!".'
  },
  {
    title: "Top Holiday Shopping Tips",
    slug: "/blog/consumer-tips/smart-holiday-shopping",
    description:
      "Find out the top tips from WaFd Bank on how you can save money and be a smart shopper this holiday season, while starting your shopping early.",
    date: "2021-11-10",
    imageFilterPath: "consumer-tips/smart-holiday-shopping/thumbnail-holiday-shopping-tips-06022023-730.jpg",
    imageAlt: "Two men talking and holding holiday gifts."
  },
  {
    title: "International Travel Tips",
    slug: "/blog/consumer-tips/traveling-abroad-tips",
    description:
      "While you're planning that vacation abroad, there are a few tips you should consider before you embark. Learn about these travel tips from WaFd Bank.",
    date: "2020-10-16",
    imageFilterPath: "consumer-tips/traveling-abroad-tips/thumbnail-international-travel-tips-032024.jpg",
    imageAlt: "Man and woman raising arms in mountains in Norway."
  },
  {
    title: "How to Use a HELOC with a 1% Discount for Unexpected Bills",
    slug: "/blog/consumer-tips/unexpected-bills-how-home-can-help",
    description:
      "Do you have unexpected Bills? Find out how your home can help you during this difficult time with the help of a home equity line of credit (HELOC).",
    date: "2024-07-17",
    imageFilterPath: "consumer-tips/unexpected-bills-how-home-can-help/thumbnail-how-to-use-heloc-unexpected-bills.jpg",
    imageAlt: "Mother and baby playing with blocks in doctors office."
  },
  {
    title: "6 Ideas to Use Your Tax Refund",
    slug: "/blog/consumer-tips/what-to-do-with-tax-refund",
    description:
      "WaFd Bank shares ways you can spend (or save) the money from your tax refund. For more tips, visit WaFd Bank's Banking 101.",
    date: "2022-04-20",
    imageFilterPath: "consumer-tips/what-to-do-with-tax-refund/thumbnail-6-ideas-to-use-your-tax-refund-091823.jpg",
    imageAlt: "Woman celebrating, looking at tax refund statement."
  },
  {
    date: "2022-04-22",
    title: "End-of-Year Financial Checklist",
    slug: "/blog/consumer-tips/year-end-good-habits",
    description:
      "WaFd Bank shares tips on ways to close out the year on firm financial footing, For more tips, visit WaFd Bank's Banking 101.",
    imageFilterPath: "consumer-tips/year-end-good-habits/thumbnail-year-financial-shape-091823.jpg",
    imageAlt: "Parents shopping with laptop at home while daughter draws."
  },
  {
    date: "2023-06-06",
    title: "How to Use a Vacation Savings Account to Pay for a Vacation",
    slug: "/blog/consumer-tips/vacation-savings-account-save-for-vacation",
    description:
      "Find out how you can make your dream vacation a reality by using a vacation savings account. Read top tips from WaFd Bank on how to save on your vacation.",
    imageFilterPath:
      "consumer-tips/vacation-savings-account-save-for-vacation/thumbnail-vacation-savings-06022023-730.jpg",
    imageAlt: "Young couple of hikers enjoying the beautiful nature from high above."
  }
];

export const familyFinanceCards = [
  {
    title: "How to Teach Your Child Financial Literacy",
    slug: "/blog/family-finance/ideas-financial-literacy-kids",
    imageFilterPath: "family-finance/ideas-financial-literacy-kids/thumbnail-teach-financial-literacy-071723.jpg",
    imageAlt: "Smiling mother and daughter putting coins into a piggy bank.",
    text: "Having good money management skills is a vital skill, and we can all agree it's important to start young so they develop good habits early on.",
    description:
      "Only 1 in 5 teenagers have basic-level skills regarding financial literacy. Find out how WaFd Bank can help you teach your kids financial skills in the home.",
    date: "2020-10-16"
  },
  {
    title: "Why is Financial Literacy Important for Teens",
    slug: "/blog/family-finance/financial-literacy-for-teens",
    imageFilterPath: "family-finance/financial-literacy-for-teens/thumbnail-financial-literacy-for-teens-091323.jpg",
    imageAlt: "Mother and daughter using a laptop.",
    description:
      "Empower your teen with financial literacy skills to help them prosper. Find out why financial literacy is important and how to teach your teen financial literacy.",
    date: "2023-09-26"
  },
  {
    title: "How to Teach Your Child the Value of Money",
    slug: "/blog/family-finance/teach-your-child-the-value-of-money",
    imageFilterPath:
      "family-finance/teach-your-child-the-value-of-money/thumbnail-teaching-kids-about-money-03082023-730.jpg",
    imageAlt: "Mother teaching daughter about saving money with coins.",
    description:
      "Find out from WaFd Bank how to teach your kid the value of money &amp; top ways to help your child learn about money so they can make good future money decisions.",
    date: "2023-03-20"
  },
  {
    title: "What You Need to Know About Paying for College",
    slug: "/blog/family-finance/saving-for-college",
    imageFilterPath: "family-finance/saving-for-college/thumbnail-paying-for-college-010824.jpg",
    imageAlt: "Young graduate woman embracing her mother on graduation.",
    text: "A college education has long been part of the American dream - the aspirational belief that all people should have the opportunity to become successful and ...",
    description:
      "WaFd Bank shows how to calculate future higher education costs and find ways to save for college. For more tips, visit WaFd Bank's Banking 101.",
    date: "2024-04-19"
  },
  {
    title: "How to Use a Savings Account to Teach Your Kids Money Management Lessons",
    slug: "/blog/family-finance/money-management-kids",
    imageFilterPath:
      "family-finance/money-management-kids/thumbnail-teaching-kids-about-managing-money-03232023-730.jpg",
    imageAlt: "Mother teaching son about saving money, putting money in piggy bank.",
    description:
      "Learning to manage money while you are young is a great time to learn. If you've got youngsters in your house, learn about these money management tips from WaFd Bank",
    date: "2020-10-16"
  },
  {
    title: "Saving Money on Back-to-School Shopping",
    slug: "/blog/family-finance/back-to-school-budgeting",
    description:
      "Find out how you can save money with top back-to-school budgeting tips from WaFd Bank to help you cut costs when you're shopping for back-to-school items.",
    date: "2023-09-21",
    imageFilterPath: "family-finance/back-to-school-budgeting/thumbnail-saving-back-school-091823.jpg",
    imageAlt: "Girl pointing at backpack while buying school supplies with her parents."
  },
  {
    title: "9 Financial Tips for College Students",
    slug: "/blog/family-finance/post-college-financial-planning",
    imageFilterPath:
      "family-finance/post-college-financial-planning/thumbnail-manage-finances-after-college-graduation-110123.jpg",
    imageAlt: "Young woman managing her finances on laptop.",
    description:
      "Are you looking to prepare for the financial future? WaFd Bank can help you with your post college financial planning so you can get out of debt and build wealth.",
    date: "2020-10-16"
  },
  {
    title: "The Save at School Savings Program at WaFd Bank",
    slug: "/blog/family-finance/student-savings-at-school",
    description:
      "Are you a student and looking to beef up your savings? Find out how you can increase your savings while enrolled in school so you are better prepared for the future.",
    date: "2020-10-16",
    imageFilterPath: "family-finance/student-savings-at-school/thumbnail-student-savings-03072023-730.jpg",
    imageAlt: "Father helping daughter count money for her piggy bank."
  },
  {
    title: "Should You Take Out Student Loans: Essential Considerations",
    slug: "/blog/family-finance/questions-to-ask-before-taking-out-student-loan",
    imageFilterPath:
      "family-finance/questions-to-ask-before-taking-out-student-loan/thumbnail-should-you-take-out-student-loans-011724.jpg",
    imageAlt: "Teenage boy studying with laptop at home.",
    text: "Thinking about taking out student loans? WaFd Bank explains the top considerations and questions to ask if you're not sure if you should take out student loans.",
    description:
      "Thinking about taking out student loans? WaFd Bank explains the top considerations and questions to ask if you're not sure if you should take out student loans.",
    date: "2020-10-16"
  },
  {
    title: "What Can You Use a HELOC For",
    slug: "/blog/family-finance/uses-for-a-heloc",
    description:
      "You may be wondering what you can use a HELOC for. You can use the equity in your home to access money consolidate debt, pay for educational expenses, and more.",
    date: "2023-11-06",
    imageFilterPath: "family-finance/uses-for-a-heloc/thumbnail-heloc-uses-103023-730.jpg",
    imageAlt: "Woman on sofa relaxed, smiling, and daydreaming."
  }
];

export const homeOwnershipCards = [
  {
    title: "How to Qualify for a Home Equity Line of Credit",
    slug: "/blog/home-ownership/how-to-qualify-for-a-heloc",
    description:
      "Discover basic requirements for a home equity line of credit to be an option and learn how to qualify for a HELOC with your local lender, WaFd Bank.",
    date: "2021-03-30",
    text: "Qualifying for a HELOC may give you some peace of mind while you tackle any financial obstacle that comes your way.",
    imageFilterPath: "home-ownership/how-to-qualify-for-a-heloc/thumbnail-qualify-for-heloc-04182023-730.jpg",
    imageAlt: "Couple Sitting On Floor With Paint Chart Ready To Decorate New Home."
  },
  {
    title: "Use a Home Equity Line of Credit to Pay Off Student Loans",
    slug: "/blog/home-ownership/home-equity-loan-college-tuition",
    description:
      "Learn about the latest home ownership tips from WaFd Bank including tips for first time home buyers, if you should buy that fixer upper, and more.",
    date: "2020-10-16",
    text: "Qualifying for a HELOC may give you some peace of mind while you tackle any financial obstacle that comes your way.",
    imageFilterPath: "home-ownership/home-equity-loan-college-tuition/thumbnail-heloc-student-loans-071223.jpg",
    imageAlt: "College student studying with laptop and writing in notebook on campus."
  },
  {
    title: "How to Build Real Estate Equity in a Tight Market",
    slug: "/blog/home-ownership/how-to-build-real-estate-equity",
    description:
      "Are you looking to build real estate equity to get ahead with your finances? Find out more in this informative article from the home loan team at WaFd Bank.",
    date: "2020-10-16",
    imageFilterPath: "home-ownership/how-to-build-real-estate-equity/thumbnail-build-equity-in-tight-market-121923.jpg",
    imageAlt: "Two men standing in a beautiful modern kitchen having coffee."
  },
  {
    title: "Mortgage Terms Explained",
    slug: "/blog/home-ownership/understanding-mortgages",
    description:
      "Buying a home is one of the biggest financial choices most of us will make. WaFd Bank is here to help you understand all about mortgages.",
    date: "2020-10-16",
    imageFilterPath: "home-ownership/understanding-mortgages/thumbnail-mortgage-terms-04182023-730.jpg",
    imageAlt: "Couple sitting at home and using laptop."
  },
  {
    title: "Mortgage Down Payment Options",
    slug: "/blog/home-ownership/mortgage-down-payment-options",
    description:
      "Maximize your mortgage down payment with WaFd Bank's various options, from permanent buy down to rent back provisions. Find the best fit for your needs.",
    date: "2023-05-18",
    imageFilterPath: "home-ownership/mortgage-down-payment-options/thumbnail-use-down-payment-05052023-730.jpg",
    imageAlt: "Couple in their new modern loft using tablet."
  },
  {
    title: "The 4 Different Types of Home Loans",
    slug: "/blog/home-ownership/types-mortgages-explained",
    description:
      "Learn about the different types of home loans and common mortgage terminology so you can navigate your way around mortgage types and find the right one for you.",
    date: "2020-10-16",
    imageFilterPath: "home-ownership/types-mortgages-explained/thumbnail-types-of-mortgages-03202023-730.jpg",
    imageAlt: "Real estate agent giving the keys of their new house to a happy couple."
  },
  {
    title: "12 Important Steps to Buying a Home",
    slug: "/blog/home-ownership/12-steps-buying-house",
    description:
      "When it comes to buying a home, there's often a lot of stress. Understanding the steps to buying a home can make things a little less scary. Tips from WaFd Bank",
    date: "2020-10-16",
    imageFilterPath: "home-ownership/12-steps-buying-house/thumbnail-12-steps-to-buying-a-home-112923.jpg",
    imageAlt: "Mature couple moving items out of boxes into new home.",
    text: "When it comes to buying a home, there's often as much stress as there is excitement."
  },
  {
    title: "Understanding Mortgage Closing Costs: Who Pays & What's Included",
    slug: "/blog/home-ownership/closing-costs",
    text: "Closing costs may vary based on your lender and the location of the home. Typically, the buyer pays for most of the closing costs, but the seller may end up paying some of the closing costs as part of the negotiation.",
    description:
      "WaFd Bank shares tips on buying a house. Before buying a new home, consider these factors to determine if you are ready.",
    date: "2024-08-06",
    imageFilterPath: "home-ownership/closing-costs/thumbnail-understanding-closing-costs-092623.jpg",
    imageAlt: "Young couple signing closing paperwork."
  },
  {
    title: "How to Buy Another House Before Selling Yours",
    slug: "/blog/home-ownership/easy-move-cross-collateral",
    text: "Whether you're a first-time homebuyer or purchasing your second home, buying and selling property is stressful. Doing both at the same time is downright daunting.",
    description:
      "Learn how an Easy Move Cross Collateral Loan, available through WaFd Bank, lets you stay in your current home while you close on your new home.",
    date: "2022-09-16",
    imageFilterPath: "home-ownership/easy-move-cross-collateral/how-to-buy-another-house-730-2x.jpg",
    imageAlt: "Family in front of their house."
  },
  {
    title: "How to Buy Your Second Home",
    slug: "/blog/home-ownership/how-to-buy-second-home",
    description:
      "Are you thinking about buying a second home? Read our guide on buying a second home from the home loan team at WaFd Bank and find out if it's right for you.",
    date: "2020-10-16",
    imageFilterPath: "home-ownership/how-to-buy-second-home/thumbnail-buy-second-home-071723.jpg",
    imageAlt: "Happy couple talking on their home balcony."
  },
  {
    title: "How to Know When to Sell Your House",
    slug: "/blog/home-ownership/selling-your-home",
    text: "Considering selling your home? If so, how do you know if the time is right? Here are a few things to ponder.",
    description:
      "WaFd Bank shares tips on selling a house. Considering selling your home? If so, how do you know if the time is right? Here are a few things to ponder.",
    date: "2022-05-12",
    imageFilterPath: "home-ownership/selling-your-home/thumbnail-when-to-sell-your-home-122623.jpg",
    imageAlt: "Couple reviewing papers and working on a laptop."
  },
  {
    title: "Pros and Cons of Owning Two Homes",
    slug: "/blog/home-ownership/should-i-buy-second-home",
    description:
      "Should I buy a second home? Four questions to help you decide if a second home makes financial sense for you from WaFd Bank.",
    date: "2020-10-16",
    imageFilterPath: "home-ownership/should-i-buy-second-home/thumbnail-owning-two-homes-06062023-730.jpg",
    imageAlt: "Yellow and red two doors with pink blooms and green ivies on the brick wall."
  },
  {
    title: "How to Buy a Home in a Seller's Market",
    slug: "/blog/home-ownership/buying-home-in-sellers-market",
    description:
      "Are you thinking about buying a home in a seller's market? Find out the top tips from the WaFd Bank team on what you should know during this interesting time.",
    date: "2020-10-16",
    imageFilterPath: "home-ownership/buying-home-in-sellers-market/thumbnail-buy-sellers-market-062923.jpg",
    imageAlt: "Exterior of a suburban home with blue siding, a white front porch, and white shutters."
  },
  {
    title: "What is a Portfolio Loan &amp; How Does it Work?",
    slug: "/blog/home-ownership/what-is-a-portfolio-loan",
    description:
      "Learn about WaFd Bank's commitment to being your local portfolio home loan lender that will never sell your mortgage to another financial institution.",
    date: "2020-11-25",
    imageFilterPath: "home-ownership/what-is-a-portfolio-loan/thumbnail-what-is-a-portfolio-loan-100924.jpg",
    imageAlt: "Loan officer explains loan details to couple in office."
  },
  {
    title: "Can I Afford a Bigger House",
    slug: "/blog/home-ownership/how-much-house-can-i-afford",
    description:
      "How much house can you really afford? Find out how to tell from the financial team at WaFd Bank so you can find the right house that fits your needs.",
    date: "2020-10-16",
    imageFilterPath: "home-ownership/how-much-house-can-i-afford/thumbnail-bigger-house-122123.jpg",
    imageAlt: "Young couple celebrating new house, jumping onto the couch."
  },
  {
    title: "When is it Worth it to Refinance",
    slug: "/blog/home-ownership/when-and-why-you-should-refinance-your-home",
    description:
      "When should you refinance your home mortgage? WaFd Bank is here to help layout the pros and cons to consider depending on your financial position and needs.",
    date: "2020-10-16",
    imageFilterPath:
      "home-ownership/when-and-why-you-should-refinance-your-home/thumbnail-worth-it-to-refinance-06062023-730.jpg",
    imageAlt: "Woman using calculator to manage expenses."
  },
  {
    title: "What to Know Before Refinancing and How to Refinance a House",
    slug: "/blog/home-ownership/things-to-consider-when-refinancing-your-home",
    description:
      "Are you thinking about refinancing your house? Find out the top things to consider when refinancing your home from the experts at WaFd Bank.",
    date: "2020-10-16",
    imageFilterPath:
      "home-ownership/things-to-consider-when-refinancing-your-home/thumbnail-consider-refinance-06132023-730.jpg",
    imageAlt: "Man sitting at home on sofa using laptop and thinking while smiling."
  },
  {
    title: "Is It Better to Buy New Construction vs an Existing Home",
    slug: "/blog/home-ownership/buying-new-construction-vs-existing-home",
    description:
      "Find out the pros and cons of buying a new construction home versus buying an existing home from WaFd Bank and find out which is the better option for you.",
    date: "2023-05-18",
    imageFilterPath:
      "home-ownership/buying-new-construction-vs-existing-home/thumbnail-new-construction-05082023-730.jpg",
    imageAlt: "New construction home still being built and sold."
  },
  {
    title: "Best Way to Buy a House for First-Time Home Buyers",
    slug: "/blog/home-ownership/best-way-to-buy-a-house",
    text: "Buying a house may sound complicated, although the process to buy a house is pretty straightforward.",
    description:
      "What to know when buying a house. Buying a house may sound complicated, learn from WaFd Bank how you can secure your dream home.",
    date: "2020-10-16",
    imageFilterPath: "home-ownership/best-way-to-buy-a-house/thumbnail-first-time-home-buyers-112123.jpg",
    imageAlt: "Father and children laughing and playing ball in the yard."
  },
  {
    title: "Should I Refinance When Interest Rates Are Going Up?",
    slug: "/blog/home-ownership/should-i-refinance-at-higher-rate",
    description:
      "Are rates high and are you considering financing your home at a higher rate? Read this article to find out if it would make sense to refinance your house.",
    date: "2020-10-16",
    imageFilterPath: "home-ownership/should-i-refinance-at-higher-rate/thumbnail-should-i-refinance-091323.jpg",
    imageAlt: "Young woman wearing hijab sitting in backyard."
  },
  {
    title: "How Do Banks Approve You for a Mortgage?",
    text: "To approve or not to approve? Which loans banks decide to fund can seem a little mysterious, and often leaves applicants feeling somewhat helpless.",
    slug: "/blog/home-ownership/what-do-mortgage-lenders-look-for",
    description:
      "What do mortgage lenders look for and how does a lender decide to approve a home loan? Find out insights from the financial experts at WaFd Bank.",
    date: "2020-10-16",
    imageFilterPath: "home-ownership/what-do-mortgage-lenders-look-for/thumbnail-approve-for-mortgage-04182023-730.jpg",
    imageAlt: "Couple taking pen from consultant to sign paperwork."
  },
  {
    title: "5 Myths that Can Devalue a Home",
    slug: "/blog/home-ownership/5-myths-devalue-home",
    description:
      "Not every home improvement can bring value to your home. Find out the top myths of things that may devalue your home that you didn't know about.",
    date: "2020-10-16",
    imageFilterPath: "home-ownership/5-myths-devalue-home/thumbnail-devalue-a-home-02132023-730.jpg",
    imageAlt: "Couple holding building plans and discussing home remodel."
  },
  {
    title: "5 Green Living Tips for Your Home",
    slug: "/blog/home-ownership/green-living-tips",
    description:
      "11% of new homebuyers purchased their home because of the home's green, or energy efficient, features. Tips from WaFd Bank to make your home more green.",
    date: "2020-10-16",
    imageFilterPath: "home-ownership/green-living-tips/thumbnail-5-green-living-tips-for-your-home-091423.jpg",
    imageAlt: "Man applying caulking around a window frame."
  },
  {
    title: "What Age Should I Buy a House",
    slug: "/blog/home-ownership/best-age-to-buy-a-house",
    description:
      "Explore the best age to buy a house based on financial stability and market conditions. Find key factors to consider before purchasing your home.",
    date: "2024-06-07",
    imageFilterPath: "home-ownership/best-age-to-buy-a-house/thumbnail-what-age-buy-house-091323.jpg",
    imageAlt: "Happy couple sitting on porch by plants."
  },
  {
    title: "5 Hidden Costs of Home Ownership",
    slug: "/blog/home-ownership/hidden-costs-owning-a-home",
    description:
      "Is owning a home the right thing for you? Find out the hidden costs of owning a home and if this is the right financial decision for you at this time.",
    date: "2020-10-16",
    imageFilterPath: "home-ownership/hidden-costs-owning-a-home/thumbnail-5-hidden-costs-home-ownership-083123.jpg",
    imageAlt: "Couple meeting with home ownership advisor."
  },
  {
    title: "How to Get Your Home Ready for Winter and Save Money on Energy Costs",
    slug: "/blog/home-ownership/home-winter-energy-saving-tips",
    description:
      "Winterizing your home can pay off big when it comes time to pay your electric or gas bills in a few months. Learn tips from WaFd Bank how to save energy in the winter",
    date: "2020-10-16",
    imageFilterPath: "home-ownership/home-winter-energy-saving-tips/thumbnail-get-home-ready-for-winter-121923.jpg",
    imageAlt: "A young family laughing while in the living room on a winter day."
  },
  {
    title: "Tips to Downsize Your Home",
    slug: "/blog/home-ownership/how-to-downsize-your-home",
    description:
      "Tips to make downsizing later in life easier. Learn from WaFd Bank what you should do when looking to downsize your home.",
    date: "2020-10-16",
    imageFilterPath: "home-ownership/how-to-downsize-your-home/thumbnail-how-to-downsize-your-home-091823.jpg",
    imageAlt: "Mature man packing to move."
  },
  {
    title: "New Home Checklist Before You Move In",
    slug: "/blog/home-ownership/new-home-checklist",
    description:
      "Secure your investment with our essential tips & checklist for new homeowners. From safety measures to maintenance, WaFd Bank guides you every step of the way.",
    date: "2022-10-21",
    imageFilterPath: "home-ownership/new-home-checklist/thumbnail-new-home-checklist-041824.jpg",
    imageAlt: "Couple standing in their new backyard smiling."
  },
  {
    title: "How to Stop Mortgage Trigger Lead Calls",
    slug: "/blog/home-ownership/how-to-stop-mortgage-trigger-lead-calls",
    description: "Find out From WaFd Bank to Stop Mortgage Trigger Lead Calls if You are Buying or Selling a Home",
    date: "2023-01-05",
    imageFilterPath:
      "home-ownership/how-to-stop-mortgage-trigger-lead-calls/thumbnail-stop-mortgage-trigger-lead-calls-01052023.jpg",
    imageAlt: "Woman looking at phone in frustration."
  },
  {
    title: "Is it Better to Remodel or Move",
    slug: "/blog/home-ownership/remodel-or-move",
    text: "Need a change? Maybe more space for a growing a family, or perhaps the look of your home could just use a refresh.",
    imageFilterPath: "home-ownership/remodel-or-move/thumbnail-better-to-remodel-or-move-091923.jpg",
    imageAlt: "Couple looking at plans with architect.",
    description:
      "Should you remodel your house or is it time to move? This is a hard decision. Find out which option is best from you from the team at WaFd Bank.",
    date: "2020-10-16"
  },
  {
    title: "Understanding WaFd Bank's Smart Start Home Loan (Down Payment Assistance Program)",
    slug: "/blog/home-ownership/smart-start",
    imageFilterPath: "home-ownership/smart-start/thumbnail-understanding-smart-start-loan-062724.jpg",
    imageAlt: "WaFd Bank Smart Start Homeowner.",
    description:
      "Learn how our Smart Start Down Payment Assistance Program works for low-to-moderate income individuals and/or families and how you can receive a $5,000 grant towards your down payment.",
    date: "2022-08-01"
  },
  {
    title: "What Are Tax Deferred Exchanges and How Do They Work?",
    slug: "/blog/home-ownership/tax-deferred-exchanges-explained",
    imageFilterPath: "home-ownership/tax-deferred-exchanges-explained/thumbnail-1031-04182023-730.jpg",
    imageAlt: "Businessman and businesswoman signing a document in board room.",
    description:
      "Find out what tax deferred exchanges are in real estate and find out how 1031 exchange and reverse 1031 exchange work from your local bank, WaFd Bank.",
    date: "2023-04-25"
  },
  {
    title: "What is a Home Equity Loan (HELOAN) and How Does it Work",
    slug: "/blog/home-ownership/what-is-heloan-how-it-works",
    description:
      "Find out what a home equity loan (HELOAN) is and find out how it differs from a home equity line of credit (HELOC) and how a HELOAN works from the team at WaFd Bank.",
    date: "2023-11-28",
    imageFilterPath: "home-ownership/what-is-heloan-how-it-works/thumbnail-what-is-a-heloan-video-still-072424.jpg",
    imageAlt: "Family talking and laughing in the kitchen."
  },
  {
    title: "When to Walk Away After Home Inspection",
    slug: "/blog/home-ownership/what-look-for-when-buying-home",
    description:
      "Read the latest WaFd Bank article detailing when you should walk away from buying a home after a home inspection to help you in your home buying journey.",
    date: "2024-04-04",
    imageFilterPath: "home-ownership/what-look-for-when-buying-home/thumbnail-walk-away-home-inspection-032124.jpg",
    imageAlt: "Couple looking sad at each other with laptop."
  },
  {
    title: "HELOAN vs HELOC: Pros and Cons",
    slug: "/blog/home-ownership/heloc-vs-heloan-pros-cons",
    description:
      "Find out the difference between a HELOC and HELOAN and pros and cons of each to help you make the best decision for how you can use your home equity.",
    date: "2024-07-24",
    imageFilterPath: "home-ownership/heloc-vs-heloan-pros-cons/thumbnail-heloan-vs-heloc-pros-cons-072224.jpg",
    imageAlt: "A happy couple standing in a glass doorway admiring their yard."
  }
];

export const securityPrivacyCards = [
  {
    title: "What You Need to Know About AI Scams",
    slug: "/blog/security-privacy/ai-scams-what-you-should-know",
    imageFilterPath:
      "security-privacy/ai-scams-what-you-should-know/thumbnail-what-you-need-to-know-about-ai-scams-100124.jpg",
    imageAlt: "Man looking frustrated while looking at his mobile phone.",
    description:
      "Learn about AI Scams and how to recognize them. WaFd Bank explains different types of AI Scams as well as how to protect yourself from these scams.",
    date: "2024-10-08"
  },
  {
    title: "Protect Your Bank Accounts",
    slug: "/blog/security-privacy/protect-your-bank-accounts",
    imageFilterPath: "security-privacy/protect-your-bank-accounts/thumbnail-protect-bank-account-02092023-730.jpg",
    imageAlt: "Woman entering secure password on smartphone, secure icon above.",
    description:
      "Find out the top tips from WaFd Bank on how to protect your bank accounts from hackers including secure passwords, Wi-Fi tips, vigilance and more.",
    date: "2023-03-08"
  },
  {
    title: "Online and Mobile Banking Security and Safety&mdash;What You Should Know",
    slug: "/blog/security-privacy/online-and-mobile-banking-security-and-safety",
    imageFilterPath:
      "security-privacy/online-and-mobile-banking-security-and-safety/thumbnail-banking-security-02092023-730.jpg",
    imageAlt: "Woman using smartphone with dollar icons above it.",
    description: "Read on to find out what you should know about online and mobile banking security and safety.",
    date: "2023-02-16"
  },
  {
    title: "Top 10 Ways to Protect Yourself Online",
    slug: "/blog/security-privacy/how-to-protect-yourself-online",
    imageFilterPath: "security-privacy/how-to-protect-yourself-online/thumbnail-cyber-security-082323.jpg",
    imageAlt: "Young man using laptop at home.",
    text: "Reminders about 10 simple things you can do to help protect your computer and money from online criminals.",
    description:
      "How to protect your financial information from online criminals. Find out how WaFd Bank can help protect you and your business.",
    date: "2020-10-16"
  },
  {
    title: "How to Identify Scams and Prevent Them",
    slug: "/blog/security-privacy/how-to-identify-prevent-scams-before-they-happen",
    imageFilterPath:
      "security-privacy/how-to-identify-prevent-scams-before-they-happen/thumbnail-detect-scams-073123.jpg",
    imageAlt: "Concerned woman looking at a smartphone sitting on a couch.",
    text: "Learn the top red flags scammers use, how to identify scams and tips to prevent being scammed online.",
    description:
      "Learn the top red flags scammers use, how to identify scams and tips to prevent being scammed online.",
    date: "2023-08-23"
  },
  {
    title: "IRS &amp; Tax Season Scams You Should Know",
    slug: "/blog/security-privacy/scam-alerts-know-about-tax-season",
    imageFilterPath: "security-privacy/scam-alerts-know-about-tax-season/thumbnail-irs-tax-season-scams-110723.jpg",
    imageAlt: "Frustrated woman looking at a laptop.",
    description:
      "Thousands of people have lost millions of dollars and their personal information to tax scams. Learn what to look for from WaFd Bank about these tax season scams.",
    date: "2020-10-16"
  },
  {
    title: "6 Ways to Keep Your Devices Secure When Shopping Online",
    slug: "/blog/security-privacy/cyber-security-tips-holidays",
    imageFilterPath: "security-privacy/cyber-security-tips-holidays/thumbnaill-online-shopping-safety-112723.jpg",
    imageAlt: "Woman holding credit card while shopping on phone outside.",
    description:
      "The holidays are approaching and it's time to start thinking about cyber security. Find out the top cyber security tips to keep your identity safe from WaFd Bank.",
    date: "2020-10-16"
  },
  {
    title: "How to Protect the Elderly from Financial Abuse",
    slug: "/blog/security-privacy/protecting-elderly-financial-abuse",
    imageFilterPath:
      "security-privacy/protecting-elderly-financial-abuse/thumbnail-protect-loved-one-from-financial-abuse-011823.jpg",
    imageAlt: "A woman helping her father with finances.",
    description:
      "Is there someone you care about experiencing Elderly Financial Abuse? Find out what it is and what to do about it from the experts at WaFd Bank.",
    date: "2020-10-16"
  },
  {
    title: "What Should You Do if Someone Files Taxes Using Your Social Security Number?",
    slug: "/blog/security-privacy/someone-else-files-taxes-my-social-security-number",
    imageFilterPath:
      "security-privacy/someone-else-files-taxes-my-social-security-number/thumbnail-taxes-using-your-ssn-110223.jpg",
    imageAlt: "Woman confused while filing taxes on laptop at home.",
    description:
      "Did someone use your social security number on their taxes? Find out the top things should know and what to do about it from the team at WaFd Bank.",
    date: "2020-10-16"
  },
  {
    title: "Can Someone Steal Your Tax Return",
    slug: "/blog/security-privacy/prevent-fraudulent-tax-filing",
    imageFilterPath:
      "security-privacy/prevent-fraudulent-tax-filing/thumbnail-prevent-fraudulent-tax-filing-091823.jpg",
    imageAlt: "Woman looking stressed while looking at a document.",
    text: "Tax fraud can be timely and costly to remedy. Tips to keep your information more secure.",
    description:
      "For many Americans, tax season can be an emotional roller coaster. Learn how to prevent fraudulent tax filing from WaFd Bank.",
    date: "2020-10-16"
  },
  {
    title: "What is Cybersecurity and How Can I Protect Myself?",
    slug: "/blog/security-privacy/cybersecurity-and-how-to-protect-yourself",
    imageFilterPath: "security-privacy/cybersecurity-and-how-to-protect-yourself/what-is-cybersecurity-730.jpg",
    imageAlt: "Woman having problems with her laptop with a confused and frustrated look.",
    description: "Learn tips on how to protect your personal cybersecurity and be safe online.",
    date: "2022-10-19"
  },
  {
    title: "How to Spot Credit Repair Scams",
    slug: "/blog/security-privacy/beware-of-credit-repair-scams",
    description:
      "Be aware of scams claiming to repair your credit file or boost your credit score. Find out how WaFd Bank can help you identify these scams.",
    date: "2024-05-22",
    imageFilterPath:
      "security-privacy/beware-of-credit-repair-scams/thumbnail-beware-of-credit-repair-scams-101923.jpg",
    imageAlt: "A frustrated man looking at a laptop."
  },
  {
    title: "8 Ways You Can Protect Yourself (and Your Identity) on Social Media",
    slug: "/blog/security-privacy/8-ways-to-protect-yourself-on-social-media",
    description:
      "Find out from the team at WaFd Bank the top ways you can protect yourself and your identity on social media, to help you stay safe online..",
    imageFilterPath:
      "security-privacy/8-ways-to-protect-yourself-on-social-media/thumbnail-8-ways-protect-yourself-on-social-media-011624.jpg",
    imageAlt: "A businesswoman with a cup of coffee is checking her cell phone."
  },
  {
    title: "How Can You Prevent Child Identity Theft",
    slug: "/blog/security-privacy/child-identity-theft",
    description:
      "Do you want to protect your child from identity theft? Read this article to understand top tips to protect your child's identity from the team at WaFd Bank.",
    date: "2024-05-06",
    imageFilterPath: "security-privacy/child-identity-theft/thumbnail-prevent-child-identity-theft-032024.jpg",
    imageAlt: "Happy girl using tablet with her father, mother in the background reading on the couch."
  },
  {
    title: "Money Safety in Banks",
    slug: "/blog/security-privacy/money-safety-in-banks",
    text: "You probably hear banks say they're insured by the FDIC, but if you're not sure what that means, you might be wondering what it has to do with keeping your money safe.",
    description: "WaFd Bank explains what it means to be FDIC insured and how we keep your money safe.",
    imageFilterPath: "security-privacy/money-safety-in-banks/thumbnail-bank-safety-03062023-730.jpg",
    imageAlt: "Depositing cash at bank counter.",
    date: "2023-03-17"
  },
  {
    title: "How Voice-Activated Banking Works",
    slug: "/blog/security-privacy/how-voice-activated-banking-works",
    description:
      "How does voice biometrics work in banking? Learn about the pros and cons of Voice-Activated Banking and how WaFd Bank is leveraging this innovative technology.",
    date: "2023-06-26",
    imageFilterPath: "security-privacy/how-voice-activated-banking-works/thumbnail-voice-activated-banking-062724.jpg",
    imageAlt: "Women singing, using her toothbrush as a microphone."
  },
  {
    title: "Password Testing Tool",
    slug: "/blog/security-privacy/password-testing-tool",
    description:
      "Use WaFd Bank's password testing tool to check the strength of your password. Our tool evaluate the security of your password help you keep your account secure.",
    date: "2023-11-16",
    imageFilterPath: "security-privacy/password-testing-tool/thumbnail-password-tool-091824.jpg",
    imageAlt: "Man typing secure password on smartphone."
  },
  {
    title: "How Safe is it to Use Apple Pay and Google Pay",
    slug: "/blog/security-privacy/google-pay-apple-pay-safety-debit-cards",
    description:
      "Read WaFd Bank's article on safety and privacy standards with using Apple Pay and Google Pay and if you should add your debit card to these digital wallets.",
    date: "2024-08-06",
    imageFilterPath:
      "security-privacy/google-pay-apple-pay-safety-debit-cards/thumbnail-how-safe-is-it-to-use-apple-google-pay-080224.jpg",
    imageAlt: "Senior man making a contactless mobile phone payment."
  }
];

export const smallBusinessCards = [
  {
    title: "Navigating Small Business Payment Options",
    slug: "/blog/small-business/payment-options",
    imageFilterPath: "small-business/payment-options/thumbnail-small-busniess-payments-120224.jpg",
    imageAlt: "Customer at mobile coffee truck making contactless payment using a smartwatch.",
    description:
      "Learn how to find the best payment options for your small business. WaFd Bank explains many ways small businesses can take payments including mobile payments.",
    date: "2024-12-05"
  },
  {
    title: "Best Social Media Sites for Small Businesses",
    slug: "/blog/small-business/best-social-media-sites-small-business",
    imageFilterPath:
      "small-business/best-social-media-sites-small-business/thumbnail-best-social-media-sites-for-small-business-100124.jpg",
    imageAlt: "Two businesswomen working on a laptop and mobile phone.",
    description:
      "Want to grow your business? Find out the best social media platforms that are relevant for small businesses and how small businesses are using social media.",
    date: "2024-10-09"
  },
  {
    title: "The Benefits of Merchant Services for Your Business",
    slug: "/blog/small-business/merchant-services-benefits",
    imageFilterPath:
      "small-business/merchant-services-benefits/thumbnail-benefits-of-merchant-services-for-your-business-091624.jpg",
    imageAlt: "Woman holding her mobile phone making a payment at a clothing store.",
    description:
      "Learn what Merchant Services is and the benefits of merchant services for your business and how you can access Merchant Services with WaFd Bank.",
    date: "2024-09-18"
  },
  {
    title: "How to Establish and Build Business Credit",
    slug: "/blog/small-business/how-to-build-business-credit",
    imageFilterPath:
      "small-business/how-to-build-business-credit/thumbnail-how-to-establish-and-build-business-credit-091724.jpg",
    imageAlt: "A businesswoman is doing bookkeeping in her ceramics shop.",
    description:
      "Find out the importance of establishing credit for your business and how to build credit for your business for the first time. Learn more with WaFd Bank",
    date: "2024-09-19"
  },
  {
    date: "2024-09-12",
    title: "Steps to Start a Small Business",
    slug: "/blog/small-business/steps-start-small-business",
    description:
      "Curious how to start your own company? WaFd Bank explains the steps to take to start your small business so you could turn your hobby into a full-time gig.",
    imageFilterPath: "small-business/steps-start-small-business/thumbnail-start-small-business-1-072224.jpg",
    imageAlt: 'Shop owner taking photo of "Open" sign in front of small business store.'
  },
  {
    title: "Finding the Right Location for Your Business",
    slug: "/blog/small-business/choosing-location-for-business",
    imageFilterPath: "small-business/choosing-location-for-business/blog-right-business-location-730.jpg",
    imageAlt: "Happy shop owner opening doors at his cafe.",
    description:
      "Are you starting a small business? Find out top tips on how to choose the best location for your business from the small business team at WaFd Bank.",
    date: "2020-10-16"
  },
  {
    title: "How to Write a Small Business Plan",
    slug: "/blog/small-business/how-to-write-small-business-plan",
    imageFilterPath:
      "small-business/how-to-write-small-business-plan/thumbnail-how-to-write-a-business-plan-091323.jpg",
    imageAlt: "Woman at a desk on laptop crafting a business plan.",
    description:
      "Are you working on creating a business plan? Find out how to write a small business plan to help your new business thrive from the financial team at WaFd Bank.",
    date: "2020-10-16"
  },
  {
    title: "What Is Needed to Open a Business Bank Account?",
    text: "Are you curious what is needed to open a business bank account? WaFd Bank can help with the personal information and required documents needed for this process.",
    slug: "/blog/small-business/what-is-needed-to-open-business-bank-account",
    imageFilterPath:
      "small-business/what-is-needed-to-open-business-bank-account/thumbnail-open-business-account-091523.jpg",
    imageAlt: "Businesswoman typing order while on phone with a client.",
    description:
      "Are you curious what is needed to open a business bank account? WaFd Bank can help with the personal information and required documents needed for this process.",
    date: "2024-03-04"
  },
  {
    title: "Small Business Cybersecurity Tips and Best Practices",
    slug: "/blog/small-business/small-business-cybersecurity-tips",
    imageFilterPath: "small-business/small-business-cybersecurity-tips/thumbnail-cyber-security-02212023-730.jpg",
    imageAlt: "Business woman smiling, holding mug and taking notes from laptop.",
    text: "How can you get your small business security practices cleaned up&quest;",
    description:
      "Safeguard your small business with WaFd Bank's cybersecurity tips. Learn to protect customer data and strengthen your defenses against cyber threats.",
    date: "2024-06-11"
  },
  {
    title: "Ensure Credit Card PCI Compliance",
    text: "Data breaches aren't a fun thing to consider as a business owner. Learn from WaFd Bank the steps you can take to help prevent fraud with your payment processing.",
    slug: "/blog/small-business/ensure-credit-card-pci-compliance",
    imageFilterPath: "small-business/ensure-credit-card-pci-compliance/thumbnail-pci-compliance-071823.jpg",
    imageAlt: "Close up mobile payment.",
    description:
      "Data breaches aren't a fun thing to consider as a business owner. Learn from WaFd Bank the steps you can take to help prevent fraud with your payment processing.",
    date: "2020-10-16"
  },
  {
    title: "How to Step Up Your Small Business Growth",
    text: "Are you looking to grow your small business? Read our guide on how to grow your business so your business can thrive in today's ever changing climate.",
    slug: "/blog/small-business/how-to-grow-small-business",
    imageFilterPath:
      "small-business/how-to-grow-small-business/thumbnail-how-to-set-up-your-small-biz-growth-103023.jpg",
    imageAlt: "Businesswoman in a warehouse, working on a laptop.",
    description:
      "Are you looking to grow your small business? Read our guide on how to grow your business so your business can thrive in today's ever changing climate.",
    date: "2020-10-16"
  },
  {
    title: "What Is the Best Bank for a Small Business?",
    text: "If you're a small business owner, it can be tough to know which bank is best for your business's needs. Find out what WaFd Bank can offer your small business.",
    slug: "/blog/small-business/what-is-the-best-bank-for-a-small-business",
    imageFilterPath:
      "small-business/what-is-the-best-bank-for-a-small-business/thumbnail-best-bank-for-small-business-010824.jpg",
    imageAlt: "Built for Business | Rapid Invoice Payment Solutions with FINSYNC",
    description:
      "If you're a small business owner, it can be tough to know which bank is best for your business needs. Find out what WaFd Bank can offer your small business.",
    date: "2020-10-16"
  },
  {
    title: "Is Starting a Business Worth It",
    text: "Are you considering starting a new business? Find out what you should know before venturing into being a business owner from the financial experts at WaFd Bank.",
    slug: "/blog/small-business/is-starting-a-business-worth-it",
    imageFilterPath:
      "small-business/3-facts-before-starting-own-business/thumbnail-is-starting-a-business-worth-it-091323.jpg",
    imageAlt: 'New restaurant owners high-five over a "we are open" sign.',
    description:
      "Are you considering starting a new business? Find out what you should know before venturing into being a business owner from the financial experts at WaFd Bank.",
    date: "2020-10-16"
  },
  {
    title: "3 Trends Your Small Business Can't Afford to Ignore",
    slug: "/blog/small-business/3-millennial-trends-your-small-business-cant-afford-to-ignore",
    description:
      "Find out the top small business millennial trends you should know to stay relevant in today's ever changing business landscape from the team at WaFd Bank.",
    date: "2020-10-16",
    imageFilterPath:
      "small-business/3-millennial-trends-your-small-business-cant-afford-to-ignore/thumbnail-trends-small-biz-can-not-ignore-103123.jpg",
    imageAlt: "Coffee shop owner smiling while in his shop."
  },
  {
    title: "How to Create a Holiday Marketing Plan for Your Small Business",
    slug: "/blog/small-business/how-to-create-holiday-marketing-plan-for-small-business",
    description:
      "Looking for a small business holiday marketing plan? We have you covered. Read this guide from WaFd Bank to help you navigate the holidays as a small business.",
    date: "2020-10-16",
    imageFilterPath:
      "small-business/how-to-create-holiday-marketing-plan-for-small-business/thumbnail-holiday-marketing-plan-small-biz-103023.jpg",
    imageAlt: "Excited mature women opening their store for the holidays."
  },
  {
    title: "Top Tips on Growing Your Small Business Using Social Media",
    slug: "/blog/small-business/how-to-grow-your-small-business-social",
    description:
      "Find out from WaFd Bank the top ways you can grow your small business using social media with top tips including tools, how to implement and more.",
    date: "2024-05-30",
    imageFilterPath:
      "small-business/how-to-grow-your-small-business-social/thumbnail-growing-your-business-with-social-media-101923.jpg",
    imageAlt: "Woman on mobile looking at social media."
  },
  {
    title: "How to Move or Expand a Business to Another State",
    slug: "/blog/small-business/how-to-move-a-business-to-another-state",
    description:
      "Moving any type of business to another state takes considerable thought and planning. WaFd Bank is here to guide you through the steps to relocate.",
    date: "2024-08-05",
    imageFilterPath:
      "small-business/how-to-move-a-business-to-another-state/thumbnail-move-business-to-another-state-101723.jpg",
    imageAlt: "People moving into a new office and unboxing."
  },
  {
    title: "What Is Needed to Open a Business Bank Account in Arizona",
    slug: "/blog/small-business/arizona-business-bank-account-requirements",
    description:
      "Learn what's needed to open a business bank account in Arizona including key documents needed. Open an Arizona business bank account today with WaFd Bank.",
    date: "2020-02-21",
    imageFilterPath:
      "small-business/state-business-bank-account-requirements/thumbnail-business-account-arizona-082724.jpg",
    imageAlt: "Flying view of Arizona."
  },
  {
    title: "What Is Needed to Open a Business Bank Account in California",
    slug: "/blog/small-business/california-business-bank-account-requirements",
    description:
      "Learn what's needed to open a business bank account in California including key documents needed. Open a California business bank account today with WaFd Bank.",
    date: "2020-02-21",
    imageFilterPath:
      "small-business/state-business-bank-account-requirements/thumbnail-business-account-california-082724.jpg",
    imageAlt: "Flying view of California."
  },
  {
    title: "What Is Needed to Open a Business Bank Account in Idaho",
    slug: "/blog/small-business/idaho-business-bank-account-requirements",
    description:
      "Learn what's needed to open a business bank account in Idaho including key documents needed. Open an Idaho business bank account today with WaFd Bank.",
    date: "2020-02-21",
    imageFilterPath:
      "small-business/state-business-bank-account-requirements/thumbnail-business-account-idaho-082724.jpg",
    imageAlt: "Flying view of Idaho."
  },
  {
    title: "What Is Needed to Open a Business Bank Account in Nevada",
    slug: "/blog/small-business/nevada-business-bank-account-requirements",
    description:
      "Learn what's needed to open a business bank account in Nevada including key documents needed. Open a Nevada business bank account today with WaFd Bank.",
    date: "2020-02-21",
    imageFilterPath:
      "small-business/state-business-bank-account-requirements/thumbnail-business-account-nevada-082724.jpg",
    imageAlt: "Flying view of Nevada."
  },
  {
    title: "What Is Needed to Open a Business Bank Account in New Mexico",
    slug: "/blog/small-business/new-mexico-business-bank-account-requirements",
    description:
      "Learn what's needed to open a business bank account in New Mexico including key documents needed. Open a New Mexico business bank account today with WaFd Bank.",
    date: "2020-02-21",
    imageFilterPath:
      "small-business/state-business-bank-account-requirements/thumbnail-business-account-new-mexico-082724.jpg",
    imageAlt: "Flying view of New Mexico."
  },
  {
    title: "What Is Needed to Open a Business Bank Account in Oregon",
    slug: "/blog/small-business/oregon-business-bank-account-requirements",
    description:
      "Learn what's needed to open a business bank account in Oregon including key documents needed. Open an Oregon business bank account today with WaFd Bank.",
    date: "2020-02-21",
    imageFilterPath:
      "small-business/state-business-bank-account-requirements/thumbnail-business-account-oregon-082724.jpg",
    imageAlt: "Flying view of Oregon."
  },
  {
    title: "What Is Needed to Open a Business Bank Account in Texas",
    slug: "/blog/small-business/texas-business-bank-account-requirements",
    description:
      "Learn what's needed to open a business bank account in Texas including key documents needed. Open a Texas business bank account today with WaFd Bank.",
    date: "2020-02-21",
    imageFilterPath:
      "small-business/state-business-bank-account-requirements/thumbnail-business-account-texas-082724.jpg",
    imageAlt: "Flying view of Texas."
  },
  {
    title: "What Is Needed to Open a Business Bank Account in Utah",
    slug: "/blog/small-business/utah-business-bank-account-requirements",
    description:
      "Learn what's needed to open a business bank account in Utah including key documents needed. Open a Utah business bank account today with WaFd Bank.",
    date: "2020-02-21",
    imageFilterPath:
      "small-business/state-business-bank-account-requirements/thumbnail-business-account-utah-082724.jpg",
    imageAlt: "Flying view of Utah."
  },
  {
    title: "What Is Needed to Open a Business Bank Account in Washington",
    slug: "/blog/small-business/washington-business-bank-account-requirements",
    description:
      "Learn what's needed to open a business bank account in Washington including key documents needed. Open a Washington business bank account today with WaFd Bank.",
    date: "2020-02-21",
    imageFilterPath:
      "small-business/state-business-bank-account-requirements/thumbnail-business-account-washington-082724.jpg",
    imageAlt: "Flying view of Washington."
  },
  {
    title: "10 Strategies to Optimize Cash Flow Management for Your Small Business",
    slug: "/blog/small-business/10-strategies-optimize-manage-cash-flow",
    description:
      "Read about 10 ways to optimize and manage cash flow for your small business. WaFd Bank details ways to improve cash flow and forecast more effectively.",
    date: "2024-12-09",
    imageFilterPath:
      "small-business/10-strategies-optimize-manage-cash-flow/thumbnail-10-cash-flow-strategies-120224.jpg",
    imageAlt: "Entrepreneur using tablet in clothing retail store."
  }
];

const nonBlogCards = [
  {
    title: "Online and Mobile Banking",
    slug: "/personal-banking/online-banking",
    imageFilterPath: "banking-101/how-to-deposit-money/thumbnail-online-mobile-banking-032524.jpg",
    imageAlt: "Woman walking outside while on a mobile phone."
  }
];

const CardBlogsData = [
  ...banking101Cards,
  ...buildingCreditCards,
  ...buildingRemodelingCards,
  ...communitySupportCards,
  ...consumerTipsCards,
  ...familyFinanceCards,
  ...homeOwnershipCards,
  ...securityPrivacyCards,
  ...smallBusinessCards,
  ...nonBlogCards
];
export default CardBlogsData;
