const EsCardBlogsData = [
  {
    date: "2024-04-19",
    title: "Lo que necesitas saber acerca de pagar por la universidad",
    slug: "/es/tu-decides/ahorrar-para-la-universidad",
    description:
      "Ahorrar para la Universidad: Lo que debes de saber. Para obtener más consejos, visite Tu Decides de WaFd Bank.",
    imageFilterPath: "ahorrar-para-la-universidad/thumbnail-paying-for-college-010824.jpg",
    imageAlt: "Young graduate woman embracing her mother on graduation."
  },
  {
    date: "2024-02-08",
    title: "Cómo aumentar su puntaje crediticio",
    slug: "/es/tu-decides/como-aumentar-su-puntaje-crediticio",
    description:
      "Puede ser un buen momento para conocer su puntaje crediticio y las formas de mejorarlo. Para obtener más consejos, visite Tu Decides de WaFd Bank.",
    text: "Si tiene una nueva casa, automóvil o préstamo en el futuro para 2022, puede ser un buen momento para conocer su puntaje crediticio y las formas de mejorarlo.",
    imageFilterPath: "como-aumentar-su-puntaje-crediticio/card-boost-credit-600.jpg",
    imageAlt: "Pareja sonriendo mientras paga facturas en una laptop."
  },
  {
    date: "2024-02-05",
    title: "Un centavo ahorrado…comience un buen hábito: consejos para aumentar sus ahorros",
    slug: "/es/tu-decides/como-lograr-este-objetivo-de-ahorro",
    description: "¿Cómo logra este objetivo de ahorro? Para obtener más consejos, visite Tu Decides de WaFd Bank.",
    imageFilterPath: "como-lograr-este-objetivo-de-ahorro/thumbnail-increase-savings-091923.jpg",
    imageAlt: "Anciana sonriendo e hija abrazándola."
  },
  {
    date: "2024-01-23",
    title: "Cómo mantener finanzas saludables en 2024",
    slug: "/es/tu-decides/como-mantener-finanzas-saludables",
    description:
      "Aquí te tenemos unas buenas resoluciones financieras y algunos consejos para poder lograrlas. Para obtener más consejos, visite Tu Decides de WaFd Bank.",
    imageFilterPath: "como-mantener-finanzas-saludables/thumbnail-how-to-stay-financially-fit-101923.jpg",
    imageAlt: "Pareja sonriendo mientras trabaja en sus finanzas."
  },
  {
    date: "2024-01-25",
    title: "Compra tu Casa: Cómo saber si estás listo para comprar",
    slug: "/es/tu-decides/compra-tu-casa",
    description:
      "Si estás buscando comprar una casa antes de que se incrementen las tasas de interés, es buena idea hacer tu tarea para asegurarte de que estás listo para hacer la compra.",
    imageFilterPath: "compra-tu-casa/thumbnail-what-age-buy-house-091323.jpg",
    imageAlt: "Pareja feliz sentada en el pórtico junto a las plantas."
  },
  {
    date: "2024-12-04",
    title: "Entendiendo los costos de cierre de la hipoteca: ¿Quién paga y qué está incluido?",
    slug: "/es/tu-decides/costos-de-cierre",
    description:
      "WaFd Bank comparte consejos para comprar una casa. Antes de comprar una casa nueva, considere estos factores para determinar si está listo.",
    imageFilterPath: "costos-de-cierre/thumbnail-understanding-closing-costs-092623.jpg",
    imageAlt: "Pareja joven firmando documentos de cierre."
  },
  {
    date: "2024-02-03",
    title: "Consejos para recortar gastos en casa",
    slug: "/es/tu-decides/consejos-para-recortar-gastos-en-casa",
    description:
      "Sigue leyendo para más ideas sobre cómo reducir costos en casa, y sobre cómo hacer un presupuesto. Para obtener más consejos, visite Tu Decides de WaFd Bank.",
    imageFilterPath: "consejos-para-recortar-gastos-en-casa/thumbnail-tip-for-cutting-costs-around-house-101923.jpg",
    imageAlt: "Pareja sonriendo mientras hace el papeleo."
  },
  {
    date: "2024-01-24",
    title: "Cómo enseñar a los hijos sobre el dinero",
    slug: "/es/tu-decides/ensenar-a-los-hijos-sobre-el-dinero",
    description:
      "Cuando se trata del futuro financiero de tus hijos, los expertos dicen que nunca es muy pronto para hablarles de dinero. Para obtener más consejos, visite Tu Decides de WaFd Bank.",
    imageFilterPath: "ensenar-a-los-hijos-sobre-el-dinero/thumbnail-teach-financial-literacy-071723.jpg",
    imageAlt: "Madre e hija sonrientes poniendo monedas en una alcancía."
  },
  {
    date: "2024-12-04",
    title: "Como prepararse financieramente para la incertidumbre económica",
    slug: "/es/tu-decides/prepararse-para-las-incertidumbres-economicas",
    description: "Aprenda consejos sobre cómo lidiar con las incertidumbres económicas en los próximos meses.",
    imageFilterPath:
      "prepararse-para-las-incertidumbres-economicas/thumbnail-prepare-economic-uncertainties-122823.jpg",
    imageAlt: "Pareja madura de mediana edad presupuestando con laptop y papeles."
  },
  {
    date: "2024-12-04",
    title: "Cómo encaminar sus finanzas",
    slug: "/es/tu-decides/mejores-consejos-para-presupuestar",
    description:
      "Así que... ¿a dónde va tu dinero y qué debes de hacer con lo que te queda de excedente?  Para obtener más consejos, visite Tu Decides de WaFd Bank.",
    imageFilterPath: "mejores-consejos-para-presupuestar/thumbnail-getting-finances-on-track-03082023-730.jpg",
    imageAlt: "Mujer feliz con gafas usando calculadora y laptop para presupuestar."
  },
  {
    date: "2024-01-29",
    title: "Lista De Verificación De Casa Nueva",
    slug: "/es/tu-decides/lista-de-verificacion-de-casa-nueva",
    description:
      "¿Casa nueva? ¿Está planeando mudarse? Asegúrese de mantener estos elementos en la parte superior de su lista de tareas pendientes.",
    imageFilterPath: "lista-de-verificacion-de-casa-nueva/new-home-600.jpg",
    imageAlt: "Mujer abrazando a hombre y sosteniendo las llaves de la casa nueva."
  },
  {
    date: "2024-01-26",
    title: "Qué hacer con el retorno de impuestos de este año",
    slug: "/es/tu-decides/que-hacer-con-el-retorno-de-impuestos-anual",
    description:
      "Para mejorar tu salud financiera, te presentamos aquí algunas maneras en las que puedes utilizar (o ahorrar) el dinero que recibas de tu retorno de impuestos. Para obtener más consejos, visite Tu Decides de WaFd Bank.",
    imageFilterPath: "que-hacer-con-el-retorno-de-impuestos-anual/thumbnail-6-ideas-to-use-your-tax-refund-091823.jpg",
    imageAlt: "Mujer celebrando, mirando la declaración de devolución de impuestos."
  },
  {
    date: "2024-02-04",
    title: "Cierra tu año con buena salud financiera",
    slug: "/es/tu-decides/termina-anualmente-con-buena-salud-financiera",
    description:
      "Aquí te presentamos algunos consejos para que puedas cerrar el año con el pie derecho cuando se trata de tus finanzas. Para obtener más consejos, visite Tu Decides de WaFd Bank.",
    imageFilterPath: "termina-anualmente-con-buena-salud-financiera/thumbnail-year-financial-shape-091823.jpg",
    imageAlt: "Padres compran con una laptop en casa mientras su hija dibuja."
  },
  {
    date: "2024-02-01",
    title: "Cómo saber cuándo vender su casa",
    slug: "/es/tu-decides/vender-su-casa",
    description: "¿Estás pensando en vender tu casa? Y si es así, ¿cómo sabes si es el momento adecuado?",
    imageFilterPath: "vender-su-casa/thumbnail-when-to-sell-your-home-122623.jpg",
    imageAlt: "Una pareja revisando papeles y trabajando en una laptop."
  },
  {
    date: "2024-12-04",
    title: "Como escribir un cheque",
    slug: "/es/tu-decides/como-escribir-un-cheque",
    description:
      "¿Primera vez que escribe un cheque? Aprenda a llenar un cheque en seis pasos con la guía completa y con fotos para escribir cheques paso a paso de WaFd Bank.",
    imageFilterPath: "como-escribir-un-cheque/thumbnail-how-to-write-a-check-010824.jpg",
    imageAlt: "Como escribir un cheque: guía paso a paso."
  },
];

export default EsCardBlogsData;
